import React, { useState } from "react";
import styled from "styled-components";
import Card from "../../Card/Card";
import DetailsCardHeader from "./DetailsCardHeader";
import LabelValue from "./LabelValue";
import AddEditForecastBudgetModal from "../../../pages/Cashflow/components/ForecastBudget/AddEditForecastBudgetModal";
import { formatCurrency } from "../../../modules";
import moment from "moment";
import { If } from "../../Utils/If";
import { MapCategory } from "../../../pages/Contacts/components/MapCategory";

interface ShowRecordProps {
  loading: boolean;
  selectedRecord: any;
  setSelectedRecord?: any;
  onClose?: any;
}

const ShowEstimate = ({
  loading,
  selectedRecord,
  setSelectedRecord,
  onClose,
}: ShowRecordProps) => {
  const [isDetailsOnlyModal, setIsDetailsOnlyModal] = useState(false);
  const [editForecastBudgetModal, setEditForecastBudgetModal] = useState(false);

  // console.log("selectedRecord", selectedRecord);

  return (
    <AnimatedView
      className="custom-scrollbar"
      style={{
        height: "calc(100vh - 90px)",
        overflowY: "scroll",
      }}
    >
      <Card
        style={{
          padding: "30px 32px",
          position: "relative",
        }}
        className="m-5 mb-6"
      >
        <DetailsCardHeader
          hasButton={true}
          buttonTitle={"Edit"}
          buttonAction={() => {
            setIsDetailsOnlyModal(true);
            setEditForecastBudgetModal(true);
          }}
          hasBadge={false}
          buttonDisabled={loading}
          title="Estimate Details"
        />

        <LabelValue
          label="Estimate name"
          value={selectedRecord?.name}
          loading={loading}
        />

        <LabelValue
          label="Total Amount"
          value={`${selectedRecord?.currency} ${formatCurrency(
            parseFloat(selectedRecord?.amount || "0"),
            2
          )}`}
          loading={loading}
        />

        <LabelValue
          label="Current estimate date"
          value={moment(selectedRecord?.dueDate).format("MMM DD, YYYY")}
          loading={loading}
        />
      </Card>

      <Card
        style={{
          padding: "30px 32px",
          position: "relative",
        }}
        className="m-5 mb-6"
      >
        <DetailsCardHeader
          hasButton={true}
          buttonTitle={"Edit"}
          buttonAction={() => {
            setIsDetailsOnlyModal(false);
            setEditForecastBudgetModal(true);
          }}
          hasBadge={false}
          buttonDisabled={loading}
          title="Estimate Settings"
        />

        <LabelValue
          label="Estimate name"
          value={selectedRecord?.name}
          loading={loading}
        />

        <LabelValue
          label="Total Amount"
          value={`${selectedRecord?.currency} ${formatCurrency(
            parseFloat(selectedRecord?.settings?.amount || "0"),
            2
          )}`}
          loading={loading}
        />

        <LabelValue
          label="Starting estimate date"
          value={moment(selectedRecord?.settings?.startForecastDate).format(
            "MMM DD, YYYY"
          )}
          loading={loading}
        />

        <LabelValue
          label="End estimate date"
          value={moment(selectedRecord?.settings?.endForecastDate).format(
            "MMM DD, YYYY"
          )}
          loading={loading}
        />

        <LabelValue
          label="Repeats"
          value={selectedRecord?.settings?.repeats}
          loading={loading}
        />

        <LabelValue
          label="Does the amount change over time?"
          value={
            selectedRecord?.settings?.valueForChangeInAmount
              ? "Yes"
              : "No, it’s Fixed"
          }
          loading={loading}
        />

        {/* <LabelValue
          label="Cashflow category"
          value={selectedRecord?.settings?.cashflowCategory}
          loading={loading}
        /> */}

        <If
          condition={
            selectedRecord?.settings?.cashflowCategoryMappings?.length > 0
          }
        >
          <MapCategory
            mode="view"
            mappings={selectedRecord?.settings?.cashflowCategoryMappings}
            showTitle={false}
          />
        </If>
        {/*
        <LabelValue
          label="Budget"
          value={selectedRecord?.settings.repeats}
          loading={loading}
          placeholder={placeholder}
        /> */}
      </Card>

      <AddEditForecastBudgetModal
        fromEstimate={true}
        opened={editForecastBudgetModal}
        onClose={() => setEditForecastBudgetModal(false)}
        type="forecast"
        isDetailsOnly={isDetailsOnlyModal}
        data={selectedRecord}
        section={selectedRecord?.settings?.type}
        editForecast={true}
      />
    </AnimatedView>
  );
};

export default ShowEstimate;

const AnimatedView = styled.div`
  /* slide from left on mount starting from parent */
  animation: slide-in-right-view 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;

  @keyframes slide-in-right-view {
    0% {
      transform: translateX(100%);
    }

    100% {
      transform: translateX(0);
    }
  }
`;
