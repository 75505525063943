import {
  IconDefinition,
  faArrowAltCircleDown,
  faArrowAltCircleUp,
  faArrowDown,
  faArrowUp,
  faCaretDown,
  faChevronUp,
  faPlus,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Fragment } from "react";
import { IconButton, Popover, Whisper } from "rsuite";
import styled, { css } from "styled-components";
import CustomButtonPrimary from "../../../common/CustomButtonPrimary/CustomButtonPrimary";
import { LoadingPlaceholders } from "../../../common/LoadingPlaceholders";
import SkipButton from "../../../common/SkipButton/SkipButton";
import { ICONS, IMAGES } from "../../../constants";
import { useAuth } from "../../../contexts";
import { formatCurrency } from "../../../modules";
import { setUserLS } from "../../../modules/localStorage";
import { updateOrganization } from "../../../repositories/organizationRepository";
import { CashflowTitles } from "../constants";
import { getPercentageDiff, getValueDiff } from "../../../modules/cashflow";
import { Skeleton } from "@mantine/core";

interface Props {
  rowHeader: string;
  rowData?: number[];
  color?: string;
  bgColor?: string;
  setIsOpen: () => void;
  isOpen: boolean;
  iconOpened?: IconDefinition;
  iconClosed?: IconDefinition;
  paddingLeft?: string;
  hasTreeIcon?: boolean;
  isBold?: boolean;
  loading?: boolean;
  key?: string;
  isRowEditable?: boolean;
  onAddBank?: () => void;
  valuesType?: string[];
  presentDateIndex?: number;
  rowType?: string;

  compareTo?: (number | undefined)[];
  compareMode?: boolean;
  isViewOnly?: boolean;
}

function ExpandableTableRow({
  rowHeader,
  rowData = [...Array(10).fill(0)],
  color = "black",
  bgColor = "#f5fcfb",
  setIsOpen,
  isOpen,
  iconOpened = faCaretDown,
  iconClosed = faCaretDown,
  paddingLeft = "5px",
  hasTreeIcon = false,
  isBold = false,
  loading,
  key,
  isRowEditable = false,
  onAddBank = () => {},
  valuesType = [],
  presentDateIndex = -1,
  rowType,
  compareTo = [],
  compareMode = false,
  isViewOnly,
}: Props) {
  const { cashflowStage, setCashflowStage, reportLoading } = useAuth();

  const whichIcon = () => {
    return isOpen ? iconOpened : iconClosed;
  };

  const rowTypeIsMainCategory = () => iconOpened === faCaretDown;

  const iconStyles = () =>
    iconOpened === faCaretDown
      ? { width: 20, height: 20 }
      : { width: 15, height: 15 };

  const whichTitle = () => {
    return isOpen ? "Collapse" : "Expand";
  };

  const whichColor = (index: number) => {
    switch (valuesType[index]) {
      case "Calculated":
        return "black";
      case "Statement":
        return "orange";
      case "Manual":
        if (presentDateIndex === -1) return "#408180";
        else return presentDateIndex < index ? "#691DB1" : "#408180";
      default:
        return "black";
    }
  };

  const whichColorForValue = (data: number) => {
    if (data < 0) {
      if (rowType === "Category") return color;
      return "#C12E5B";
    } else if (data == 0) return "#969696";
    return color;
  };

  const renderLoading = () => (
    <LoadingPlaceholders.Text
      active={true}
      inline
      width={80}
      height={25}
      style={{ borderRadius: "10%" }}
    />
  );

  const handleOnSkip = async () => {
    setCashflowStage(8);
    setUserLS({ completedTutorial: true });
    await updateOrganization({ completedTutorial: true });
  };

  const speaker = (
    <Popover title="Opening Balance" className="tutorial-popover" arrow={false}>
      <div className="popover-container">
        <p className="tutorial-popover-subtitle">
          This is where you can add all your “bank accounts” and <br />
          how much money did you have at the start of each day.
        </p>
        <img
          src={IMAGES.openingBalanceTutorial}
          className="tutorial-popover-img"
          alt="tutorial-popover-img"
        />

        <CustomButtonPrimary
          handleOnClick={() => {
            setCashflowStage(2);
          }}
        />
        <SkipButton handleOnClick={handleOnSkip} />
      </div>
    </Popover>
  );

  const renderActions = () => {
    switch (rowHeader) {
      case CashflowTitles.OpeningBalance:
        return (
          <>
            {reportLoading ? (
              <Skeleton height={"22.5px"} width={"24.5px"} radius="sm" />
            ) : (
              <Whisper
                placement="autoHorizontalEnd"
                style={{ position: "relative" }}
                open={cashflowStage === 1}
                speaker={speaker}
              >
                <div
                  className={
                    cashflowStage === 1 ? "tutorial-btn-container" : ""
                  }
                >
                  <IconButton
                    disabled={isViewOnly}
                    size="xs"
                    style={{ backgroundColor: "#91c3be" }}
                    icon={<FontAwesomeIcon icon={faPlus} color="white" />}
                    onClick={onAddBank}
                    title="Add new bank row"
                  />
                </div>
              </Whisper>
            )}
          </>
        );
      default:
        return <div />;
    }
  };

  return (
    <Fragment key={key}>
      <tr style={{ backgroundColor: bgColor }}>
        {reportLoading ? (
          <>
            <RowHeader
              className="p-4"
              style={{
                paddingLeft: paddingLeft,
                position: cashflowStage === 1 ? "relative" : "sticky",
                left: 0,
                backgroundColor: "white",
              }}
            >
              <IconContainer>
                {hasTreeIcon && <Icon src={ICONS.treeIcon} alt="tree icon" />}
                <Skeleton height={"13.13px"} width={"13.13px"} radius="sm" />
                <Value className="ml-2">{rowHeader}</Value>
              </IconContainer>
              <div>{isRowEditable ? renderActions() : null}</div>
            </RowHeader>
          </>
        ) : (
          <>
            <RowHeader
              className="p-4"
              style={{
                paddingLeft: paddingLeft,
                position: cashflowStage === 1 ? "relative" : "sticky",
                left: 0,
                backgroundColor: "white",
              }}
            >
              <IconContainer onClick={setIsOpen}>
                {hasTreeIcon && <Icon src={ICONS.treeIcon} alt="tree icon" />}
                <ExpandDataIcon
                  icon={whichIcon()}
                  style={iconStyles()}
                  title={whichTitle()}
                  //@ts-ignore
                  isopen={isOpen?.toString()}
                  rowtypeismaincategory={rowTypeIsMainCategory().toString()}
                />
                <Value className="ml-2">{rowHeader}</Value>
              </IconContainer>
              <div>{isRowEditable ? renderActions() : null}</div>
            </RowHeader>
          </>
        )}

        {rowData?.map((actualValue: number, index: number) => {
          let _compareTo = compareTo[index];
          let valueDiff = getValueDiff(actualValue, _compareTo);
          let precentDiff = getPercentageDiff(actualValue, _compareTo);

          return (
            <td
              key={index}
              className="p-4"
              style={{
                color:
                  rowHeader === CashflowTitles.OpeningBalance && valuesType
                    ? whichColor(index)
                    : whichColorForValue(actualValue),
                // textAlign: "end",
                minWidth: "195px",
                fontWeight: isBold ? "700" : "500",
                // verticalAlign: "middle",
              }}
            >
              <ValueBox>
                {compareMode && _compareTo !== undefined ? (
                  <Fragment>
                    <Actual>
                      <div
                        title="Value from saved version"
                        style={{ color: "#707070", fontWeight: "600" }}
                      >
                        {formatCurrency(_compareTo || 0, 2)}
                      </div>
                      <div
                        title="Actual value"
                        style={{ color: "#000", fontWeight: "600" }}
                      >
                        {loading
                          ? renderLoading()
                          : formatCurrency(actualValue || 0, 2)}
                      </div>
                    </Actual>
                    <Estimate>
                      <Fragment>
                        <div
                          style={{
                            fontSize: 9,
                            color: "#707070",
                          }}
                        >
                          {/* {formatCurrency(valueDiff, 2)}{" "} */}
                        </div>
                        <div
                          title="Difference"
                          style={{
                            fontSize: 9,
                            color: "black",
                          }}
                        >
                          {formatCurrency(precentDiff, 2)}%{" "}
                          {/* <ArrowUpIcon fill="red" color="red" /> */}
                          {valueDiff !== 0 ? (
                            <FontAwesomeIcon
                              icon={valueDiff > 0 ? faArrowUp : faArrowDown}
                              color={valueDiff > 0 ? "green" : "red"}
                            />
                          ) : null}
                        </div>
                      </Fragment>
                    </Estimate>
                  </Fragment>
                ) : (
                  <div style={{ textAlign: "right" }}>
                    {loading
                      ? renderLoading()
                      : formatCurrency(actualValue || 0, 2)}
                  </div>
                )}
              </ValueBox>
            </td>
          );
        })}
      </tr>
    </Fragment>
  );
}

export default ExpandableTableRow;

const IconContainer = styled.div`
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const Icon = styled.img`
  width: 25px;
  height: 10px;
  margin-bottom: 10px;
  margin-left: 5px;
  margin-right: 5px;
  user-select: none;
`;

const RowHeader = styled.th`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

const Value = styled.div`
  user-select: none;
`;

const Actual = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 10px;
  /* font-size: 11px; */
`;

const Estimate = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  /* font-size: 11px; */
`;
const ValueBox = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
`;

const ExpandDataIcon = styled(FontAwesomeIcon)`
  /* animate icon change from plus to negative based on isOpen from props */
  transition: transform 0.2s ease-in-out;

  ${(props: any) =>
    props.rowtypeismaincategory === "true"
      ? css`
          transform: ${(props: any) =>
            props.isopen === "true" ? "rotate(0)" : "rotate(-90deg)"};
        `
      : css`
          transform: ${(props: any) =>
            props.isopen === "true" ? "rotate(180deg)" : "rotate(0)"};
        `}
  cursor: pointer;
  user-select: none;
`;
