import {
  faArrowDown,
  faArrowUp,
  faUniversity,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import _ from "lodash";
import { Fragment, useState } from "react";
import styled from "styled-components";
import { LoadingPlaceholders } from "../../../../common/LoadingPlaceholders";
import { ICONS } from "../../../../constants";
import { formatCurrency } from "../../../../modules";
import RowActions from "./RowActions";
import UploadCSVModal from "./UploadCSVModal";
import { getPercentageDiff, getValueDiff } from "../../../../modules/cashflow";
import { Skeleton, Switch } from "@mantine/core";

interface Props {
  id?: string;
  rowHeader: string;
  rowData?: number[];
  color?: string;
  bgColor?: string;
  paddingLeft?: string;
  headerMinWidth?: string;
  isBold?: boolean;
  editable?: boolean;

  loading?: boolean;
  toggleRowEditable?: (id: string, cancel?: boolean) => void;
  removeRow?: (id: string) => void;
  disableRow?: (id: string) => void;
  onChangeBankName?: (id: string, rowName: string) => void;
  onChangeBankValues?: (id: string, values: number[]) => void;
  onUpsertBank?: (args: {
    id: string;
    name: string;
    values: number[];
    editedIndices: number[];
  }) => void;
  isRowEditable?: boolean;
  disabled?: boolean;
  valuesType?: string[];
  presentDateIndex?: number;
  currentPeriod?: number;
  row?: any;

  compareTo?: (number | undefined)[];
  compareMode?: boolean;
  isViewOnly?: boolean;
  reportLoading?: boolean;
  fetchLinkedBanks?: () => void;
}

function EditableTableRow({
  id,
  rowHeader,
  rowData = [...Array(10).fill(0)],
  color = "black",
  bgColor = "#f5fcfb",
  headerMinWidth = "270px",
  paddingLeft = "5px",
  isBold = false,
  loading,
  editable = false,
  toggleRowEditable = () => {},
  removeRow = () => {},
  disableRow = () => {},
  disabled = false,
  onChangeBankName = () => {},
  onChangeBankValues = () => {},
  onUpsertBank = () => {},
  valuesType = [],
  presentDateIndex = -1,

  compareTo = [],
  compareMode = false,
  isViewOnly,
  reportLoading,
  fetchLinkedBanks = () => {},
}: Props) {
  const getLoading = () => (
    <LoadingPlaceholders.Text
      active={true}
      inline
      width={80}
      height={25}
      style={{ borderRadius: "10%" }}
    />
  );

  const Rows = () => {
    const [rowName, setRowName] = useState(rowHeader);
    const [row, setRow] = useState(rowData);
    const [editedIndices, setEditedIndices] = useState<number[]>([]);

    const shouldAllowEdit = () => !disabled && _.isEqual(row, rowData);

    const isInPast = (index: number) => {
      return !(presentDateIndex < index);
    };

    const shouldAllowSave = () => false;

    const whichTooltip = (valueType: string) => {
      switch (valueType) {
        case "Calculated":
          return "Value calculated automatically";
        case "Statement":
          return "Value from uploaded bank statement";
        case "Manual":
          return "Manually entered value";
        default:
          return "Double click to edit";
      }
    };

    const whichColor = (index: number) => {
      switch (valuesType[index]) {
        case "Calculated":
          return "#1D1D1D";
        case "Statement":
          return "orange";
        case "Manual":
          if (presentDateIndex === -1) return "#408180 ";
          else return presentDateIndex < index ? "#691DB1" : "#408180";
        default:
          return "#1D1D1D";
      }
    };

    const renderActions = () => {
      switch (rowHeader) {
        default:
          return (
            <RowActions
              isViewOnly={isViewOnly}
              editable={editable}
              onSave={() => {
                if (rowName === "") {
                  return alert("Bank name cannot be empty");
                }
                id && toggleRowEditable(id);
                id && onChangeBankName(id, rowName);
                id && onChangeBankValues(id, row);

                let values = row;
                if (editedIndices.length > 0) {
                  values = row.filter((_: any, index: number) =>
                    editedIndices.includes(index)
                  );
                  // alert(JSON.stringify(values));
                }
                onUpsertBank({
                  id: id || "",
                  name: rowName,
                  values,
                  editedIndices,
                });
              }}
              onCancel={() => {
                id && toggleRowEditable(id, true);
              }}
              onDisable={() => id && disableRow(id)}
              onDelete={() => id && removeRow(id)}
              disabled={disabled}
              disableSave={shouldAllowSave()}
              onUpload={() => {
                showModal(true);
              }}
            />
          );
      }
    };

    return (
      <Fragment>
        <th
          className="p-4"
          style={{
            minWidth: headerMinWidth,
            fontWeight: "700",
            paddingLeft,
            position: "sticky",
            left: 0,
            top: 0,
            backgroundColor: "white",
            zIndex: 1,
          }}
        >
          {loading ? (
            getLoading()
          ) : (
            <TitleContainer>
              <div>
                {editable ? (
                  <Input
                    value={rowName}
                    style={{ textAlign: "left" }}
                    onChange={(e) => {
                      setRowName(e.target.value);
                    }}
                  />
                ) : (
                  <div
                    className="is-flex is-flex-direction-row is-align-items-center"
                    style={{ opacity: disabled ? 0.5 : 1, marginTop: 3 }}
                  >
                    <Icon src={ICONS.treeIcon} alt="tree icon" />
                    <FontAwesomeIcon className="mr-2" icon={faUniversity} />
                    <Value
                      title={rowHeader}
                      style={{ marginTop: 3, width: 150 }}
                      onDoubleClick={() =>
                        shouldAllowEdit() && id && toggleRowEditable(id, false)
                      }
                    >
                      {rowHeader}
                    </Value>
                  </div>
                )}
              </div>
              {reportLoading ? (
                <Skeleton height={"22.5px"} width={"24.5px"} radius="sm" />
              ) : (
                renderActions()
              )}
            </TitleContainer>
          )}
        </th>

        {row?.map((actualValue: any, index: number) => {
          let _compareTo = compareTo[index];
          let valueDiff = getValueDiff(actualValue, _compareTo);
          let precentDiff = getPercentageDiff(actualValue, _compareTo);

          return (
            <TableData
              key={index}
              className="p-4 "
              style={{
                // color: data < 0 ? "red" : color,
                fontWeight: isBold ? "700" : "500",
                opacity: disabled ? 0.5 : 1,
                textAlign: "end",
                minWidth: "135px",
                color: valuesType ? whichColor(index) : color,
              }}
              //@ts-ignore
              valueType={valuesType[index]}
            >
              <ValueBox>
                {compareMode && _compareTo !== undefined ? (
                  <Fragment>
                    <Actual>
                      <div
                        title="Value from saved version"
                        style={{ color: "#707070", fontWeight: "600" }}
                      >
                        {formatCurrency(_compareTo || 0, 2)}
                      </div>
                      <div
                        title="Actual value"
                        style={{ color: "#000", fontWeight: "600" }}
                      >
                        {loading
                          ? getLoading()
                          : formatCurrency(actualValue || 0, 2)}
                      </div>
                    </Actual>
                    <Estimate>
                      <Fragment>
                        <div
                          style={{
                            fontSize: 10,
                            color: "#707070",
                          }}
                        >
                          {/* {formatCurrency(valueDiff, 2)}{" "} */}
                        </div>
                        <div
                          title="Difference"
                          style={{
                            fontSize: 10,
                            color: "black",
                          }}
                        >
                          {formatCurrency(precentDiff, 2)}%{" "}
                          {/* <ArrowUpIcon fill="red" color="red" /> */}
                          {valueDiff != 0 ? (
                            <FontAwesomeIcon
                              icon={valueDiff > 0 ? faArrowUp : faArrowDown}
                              color={valueDiff > 0 ? "green" : "red"}
                            />
                          ) : null}
                        </div>
                      </Fragment>
                    </Estimate>
                  </Fragment>
                ) : (
                  <div style={{ textAlign: "right" }}>
                    {loading ? (
                      getLoading()
                    ) : editable && isInPast(index) ? ( // is not in future
                      <Input
                        type={"number"}
                        value={
                          ["Manual", "Statement"].includes(valuesType[index])
                            ? actualValue
                            : undefined
                        }
                        onChange={(e) => {
                          const newRow = [...row];
                          newRow[index] = parseFloat(e.target.value);
                          setRow(newRow);
                          // set indices no duplicates
                          const newIndices = [...editedIndices];
                          if (!newIndices.includes(index)) {
                            newIndices.push(index);
                          }
                          setEditedIndices(newIndices);
                        }}
                        onFocus={(e) => {
                          e.target.select();
                        }}
                      />
                    ) : (
                      <Value
                        title={whichTooltip(valuesType[index])}
                        onDoubleClick={() => {
                          if (isViewOnly) return;
                          if (reportLoading) return;
                          shouldAllowEdit()
                            ? isInPast(index) // is not in future
                              ? id && toggleRowEditable(id, false)
                              : alert("Cannot edit opening balance in future")
                            : alert("Enable row to edit values.");
                        }}
                      >
                        {actualValue === null ||
                        actualValue === undefined ||
                        isNaN(actualValue)
                          ? "-"
                          : formatCurrency(actualValue, 2)}
                      </Value>
                    )}
                  </div>
                )}
              </ValueBox>
            </TableData>
          );
        })}
      </Fragment>
    );
  };

  const [modal, showModal] = useState(false);

  return (
    <Fragment>
      <tr
        key={id}
        style={{
          backgroundColor: bgColor,
        }}
      >
        <Rows />
      </tr>

      <UploadCSVModal
        open={modal}
        // onClose={() => {
        //   showModal(false);
        // }}
        onClose={(uploadDone) => {
          showModal(false);
          if (uploadDone) {
            fetchLinkedBanks();
          }
        }}
        bank={{ name: rowHeader, id: id }}
      />
    </Fragment>
  );
}

export default EditableTableRow;

const TableData = styled.td`
  /** if valueType from props */
  /* background: ${(props: any) =>
    props.valueType === "Statement"
      ? "linear-gradient(to right, rgba(0,255,0,0.02), rgba(0,255,0,0.06))"
      : props.valueType === "Manual"
      ? "linear-gradient(to right, rgba(255,0,0,0.02), rgba(255,0,0,0.06))"
      : undefined}; */

  background-size: 100% 100%;
`;

const Input = styled.input`
  border: none;
  outline: none;
  background: none;
  border-bottom: 1px solid black;
  width: 75%;
  text-align: end;

  /* disable spinner */
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;

const TitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

const Value = styled.div`
  user-select: none;
  cursor: text;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  padding-right: 3px;
`;

const Icon = styled.img`
  width: 25px;
  height: 10px;
  margin-bottom: 10px;
  margin-left: 5px;
  margin-right: 5px;
  user-select: none;
`;

const Strip = styled.div`
  display: inline-block;
  user-select: none;
  align-self: center;
  margin-left: 3px;
`;

const Actual = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  /* font-size: 11px; */
  gap: 10px;
`;

const Estimate = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  /* font-size: 11px; */
`;
const ValueBox = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
`;
